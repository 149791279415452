import { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import moment from 'moment';
import Router from 'next/router';
import getConfig from 'next/config';
import {useSession} from "next-auth/react";
const SessionTimeout = () => {
    const [events, setEvents] = useState(['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']);
    const { data: session } = useSession();
    const isAuthenticated = session && session.user ? true : false;
    const nextConfig = getConfig();
    const [isOpen, setIsOpen] = useState(false);
    const logout = () => {
        Router.push('/api/auth/logout');
    };

    let timeStamp;
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();
    sessionStorage.setItem('lastTimeStamp', moment());
    // start inactive check
    let timeChecker = () => {
        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
            warningInactive(storedTimeStamp);
        }, nextConfig.publicRuntimeConfig.sessionTimeoutInterval);
    };
    // warning timer
    let warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current);
        warningInactiveInterval.current = setInterval(() => {
            const maxTime = 2;
            const popTime = 1;

            const diff = moment.duration(moment().diff(moment(timeString)));
            const minPast = diff.minutes();
            const leftSecond = 60 - diff.seconds();

            if (minPast === popTime) {
                setIsOpen(true);
            }

            if (minPast === maxTime) {
                clearInterval(warningInactiveInterval.current);
                setIsOpen(false);
                sessionStorage.removeItem('lastTimeStamp');
                logout();
            }
        }, 1000);
    };

    // reset interval timer
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if (isAuthenticated) {
            timeStamp = moment();
            const duration = moment.duration(moment(timeStamp).diff(moment(sessionStorage.getItem('lastTimeStamp'))));
            if (duration.hours() * 60 * 60 + duration.minutes() * 60 + duration.seconds() > 3600) {
                logout();
            }
            sessionStorage.setItem('lastTimeStamp', timeStamp);
        } else {
            clearInterval(warningInactiveInterval.current);
            sessionStorage.removeItem('lastTimeStamp');
        }
        timeChecker();
        setIsOpen(false);
    }, [isAuthenticated]);

    const handleClose = (e) => {
        e.preventDefault();
        setIsOpen(false);
        resetTimer();
    };

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });
    }, []);
    useEffect(() => {
        timeChecker();
        return () => {
            clearTimeout(startTimerInterval.current);
        };
    }, [resetTimer, events, timeChecker]);

    if (!isOpen) {
        return <Fragment />;
    }

    return (
        <div className='modal fade' tabIndex='-1'>
            <div className='modal-dialog  modal-dialog-centered'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title' id='staticBackdropLabel'>
                            {'Log out'}
                        </h5>
                        <button type='button' className='btn-close' onClick={handleClose} aria-label='Close'></button>
                    </div>
                    <div className='modal-body bg-danger bg-gradient'>You are being logged out due to inactivity.</div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' onClick={handleClose}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionTimeout;
