import getConfig from 'next/config';
import Head from 'next/head';
import MccHeader from './MccHeader';
import MccFooter from './MccFooter';
import { useUserContext } from '../userContext';
import styles from '../../styles/layout.module.css';
import SessionTimeout from '../SessionTimeout';
import { useEffect, useState } from 'react';

const nextConfig = getConfig();
export const siteTitle = `MCC Manager`;
export const mccNavyBlue = '#002563';
export const mccLightBlue = '#9ad8fd';
export const mccRed = '#a92f3f';
export const primaryFonts = 'Gotham SSm A, Gotham SSm B, Helvetica, sans-serif';
export const secondaryFonts = 'warnock-pro-1, warnock-pro-2, Times New Roman, Times, serif';

function MccDecorator({ children, title }) {
    const faviconPath = `${nextConfig.publicRuntimeConfig.basePath}/images/favicon.ico`;
    const user = useUserContext();
    const [isUserAllowed, setIsUserAllowed] = useState(null);
    let env = nextConfig.publicRuntimeConfig.deploymentEnv;

    useEffect(() => {
        validateUserPermissions();
    }, [user]);

    const validateUserPermissions = () => {
        if (user) {
            setIsUserAllowed(user.isMccAdmin || 
            user.isMccQualityReview || 
            user.isMccTechnicalContentManager || 
            user.isMccIcpsrPermitted || 
            user.isMccIcpsrArchiveManager);
        } 
    }
    const headComponent = (
        <Head>
            <link rel='icon' href={faviconPath} />
            <meta name='description' content='A tool for managing MCC products.' />
            <meta name='og:title' content={siteTitle} />
            {env !== 'prod' ? <meta name='robots' content='noindex, nofollow' /> : ''}
            <title>{title ? title + ' | MCC' : 'MCC'}</title>
        </Head>
    );

    const UserNotPermittedMsgComponent = (
        <div className='alert alert-danger' role='alert'>
                <p>Only users with the role:</p>
                <ul>
                    <li>mcc-admins</li>
                    <li>mcc-technical-content-managers</li>
                    <li>mcc-quality-review</li>
                    <li>mcc-icpsr-permitted</li>
                </ul>

                <p>are allowed to view this page.</p>
                {user && user.roles && user.roles.length > 0 ? (
                    <>
                        <p>Your current roles are:</p>
                        <ul>
                            {user.roles.map((role, i) => (
                                <li key={i}>{role.roleLabel}</li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <></>
                )}
            </div>
    );

    return (
        <>
            {headComponent}
            <div id='skip' className={styles.skipNav}>
                <a href='#mainContent'>Skip to Main Content</a>
            </div>
            <MccHeader />
            {/* <main id='mainContent' className={styles.main}> */}
            <main id='mainContent' className={`${styles.main} mt-3`}>
                {(isUserAllowed === null || isUserAllowed === undefined) && (
                    <p>Loading...</p>
                )}
                {isUserAllowed === false && (
                    UserNotPermittedMsgComponent 
                )}
                {isUserAllowed === true && (
                    <>
                        <div className='container-fluid'>{children}</div>
                        <SessionTimeout />
                    </>
                )}
            </main>
            <MccFooter />
        </>
    )
}

export default MccDecorator;
