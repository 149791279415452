import getConfig from "next/config";
import styles from "./MccFooter.module.css";

const MccFooter = () => {
  const nextConfig = getConfig();
  const icpsrFooterImagePath = `${nextConfig.publicRuntimeConfig.basePath}/images/icpsr-acronym-long-WHITE.png`;
  return (
    <footer className={`container-fluid bg-primary p-2 ${styles.footer}`}>
      <p>
        &copy;
        <span id='currentYear'>2021</span>{" "}
        <a
          href='http://regents.umich.edu/'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.footerLink}
        >
          The Regents of the University of Michigan
        </a>
        . ICPSR is part of the{" "}
        <a
          href='http://www.isr.umich.edu/'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.footerLink}
        >
          Institute for Social Research
        </a>{" "}
        at the{" "}
        <a
          href='http://www.umich.edu/'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.footerLink}
        >
          University of Michigan
        </a>
        .
      </p>
      <p>
        <img src={icpsrFooterImagePath} alt=' ' />
      </p>
      <p>
        <a
          href={nextConfig.publicRuntimeConfig.webUrl}
          className={styles.footerLink}
        >
          public site
        </a>
      </p>
    </footer>
  );
};

export default MccFooter;
